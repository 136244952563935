<template>
    <div class="toast-wrap" v-if="showToast">
        <div class="toast-body">{{ msg }}</div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            showToast: false,
            msg: ""
        };
    },
    methods: {
        fnShow({msg, duration = 1500}) {
            this.showToast = true;
            this.msg = msg;
            this.toastTimeout = setTimeout(() => {
                this.showToast = false;
            }, duration);
        },
    },
};
</script>

<style lang="less" scoped>
.toast-wrap {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
}
.toast-body {
    width: fit-content;
    max-width: 80%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
}
</style>
