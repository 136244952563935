<template>
    <div class="feadback-wrap">
        <div class="feadback-header">
            意见反馈
        </div>
        <div class="textarea-wrap">
            <textarea class="textarea" cols="6" :maxlength="300" placeholder="请输入您的宝贵意见" v-model.trim="suggestion"></textarea>
            <div class="text-area-number">{{suggestion.length}}/300</div>
        </div>
        <div class="input-wrap">
            <input class="feadback-input" :maxlength="50" type="text" placeholder="请输入您的联系方式(电话/邮箱)" v-model.trim="phone" />
        </div>
        <div class="input-wrap">
            <input class="feadback-input"  :maxlength="200" type="text" placeholder="请输入您的店铺名称" v-model.trim="shopName" />
        </div>
        <div class="submit-btn ellipsis" :class="{'disabled': btnDisabled}" @click="fnSubmit">提交反馈意见</div>
        <toast ref="toast" />
    </div>
</template>

<script>
import toast from '@/components/common/toast';
export default {
    components: {
        toast
    },
    data() {
        return {
            suggestion:'',
            phone:'',
            shopName: '',
        };
    },
    computed: {
        btnDisabled(){
            return !this.suggestion || !this.phone || !this.shopName;
        },
    },
    methods: {
        fnSubmit(){
            if(this.btnDisabled){
                let msg = '';
                if(!this.suggestion){
                    msg = '请输入意见';
                }
                if(!this.phone){
                    msg = '请输入联系方式';
                }
                if(!this.shopName){
                    msg = '请输入店铺名称';
                }
                this.$refs.toast.fnShow({
                    msg: msg
                });
                return ;
            }
            this.$post({
                url: '/hireFeedback/submit',
                data: {
                    feed_content: this.suggestion,
                    feed_contact: this.phone,
                    shop_title: this.shopName,
                },
                success: resp => {
                    console.log(resp);
                    if(resp.code == 1){
                        this.$router.replace({
                            path:'/feadback/success'
                        });
                    }else{
                        this.$refs.toast.fnShow({
                            msg: resp.msg
                        });
                    }
                },
                fail: error => {
                    console.log(error);
                    this.$refs.toast.fnShow({
                        msg: '请稍后重试'
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.feadback-wrap{
    transition: all 0.3s ease-in-out;
}
.submit-btn{
    user-select: none;
    cursor: pointer;
}
@media (max-width : 500px) {
    .feadback-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .feadback-header{
        display: none;
    }
    .textarea-wrap{
        width: 100%;
        background: #fff;
        box-sizing: border-box;
        margin-top: 10px;
        padding: 15px;
        border-radius: 4px;
        .textarea{
            resize: none;
            width: 100%;
            height: 132px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            border: 0;
            outline: none;
            color: #333;
            &::placeholder{
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #999;
            }
        }
        .text-area-number{
            width: 100%;
            box-sizing: border-box;
            font-size: 10px;
            color: #999999;
            line-height: 10px;
            text-align: right;
            margin-top: 10px;
        }
    }
    .input-wrap{
        width: 100%;
        background: #fff;
        margin-top: 15px;
        .feadback-input{
            width: 100%;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 14px;
            outline: none;
            &::placeholder{
                width: 199px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
            }
        }
    }
    .submit-btn{
        margin: 50px 9px 20px;
        background: #20A0FF;
        color: #fff;
        text-align: center;
        line-height: 46px;
        width: 97%;
        border-radius: 46px;
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
        &:active{
            background: rgba(32, 160, 255, 0.7);
        }
        &.disabled{
            background: rgba(32, 160, 255, 0.5);
        }
    }
}
/*>=768的设备*/
@media (min-width: 500px) {
    .feadback-wrap {
        width: 100%;
        max-width: 1000px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        margin: 10px auto;
    }
    .feadback-header{
        width: 100%;
        border-bottom: 1px solid rgba(240, 242, 245, 1);
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        padding-left: 24px;
        box-sizing: border-box;
        height: 80px;
    }
    .textarea-wrap{
        width: 88%;
        background: #fff;
        box-sizing: border-box;
        margin-top: 35px;
        padding: 15px;
        border-radius: 4px;
        border: 1px solid #CDD3E0;
        .textarea{
            resize: none;
            width: 100%;
            height: 132px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            border: 0;
            outline: none;
            &::placeholder{
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #999;
            }
        }
        .text-area-number{
            width: 100%;
            box-sizing: border-box;
            font-size: 10px;
            color: #999999;
            line-height: 10px;
            text-align: right;
            margin-top: 10px;
        }
    }
    .input-wrap{
        width: 88%;
        background: #fff;
        margin-top: 16px;
        border-radius: 4px;
        border: 1px solid #CDD3E0;
        .feadback-input{
            width: 100%;
            background: #FFFFFF;
            border-radius: 4px;
            padding: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 14px;
            outline: none;
            &::placeholder{
                width: 199px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
            }
        }
    }
    .submit-btn{
        margin: 40px 0 50px 6%;
        background: #20A0FF;
        color: #fff;
        text-align: center;
        line-height: 46px;
        width: 174px;
        border-radius: 46px;
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
        align-self: flex-start;
        &:active{
            background: rgba(32, 160, 255, 0.7);
        }
        &.disabled{
            background: rgba(32, 160, 255, 0.5);
        }
    }
}

@media (min-width: 1000px){
    .feadback-wrap {
        margin: 32px auto 20px;
    }
}
</style>
